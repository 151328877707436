.botao:hover {
    border: 1px solid #f89116;
}
.ant-radio-inner:after {
    background-color: #f89116;
}
.ant-radio-input:focus+.ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
    border-color: #f89116;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #f89116
}
.ant-switch-checked { 
    background-color: #f89116;
}
.ant-btn.ant-btn-primary {
    background-color: #f89116;
    border: 1px solid #f89116;
}