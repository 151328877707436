.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.ant-menu {
    background: #10585b;
    height: 94.5%;
    width: 100%;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
    background: #0d484a;
}
.ant-menu-title-content {
    color: #d6d6d6;
}
.ant-menu-title-content:hover {
    color: white;
}
.anticon.anticon-home.ant-menu-item-icon {
    color: #d6d6d6;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #10585b;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.ant-menu::-webkit-scrollbar {
    width: 8px;
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.ant-menu::-webkit-scrollbar-thumb {
    background-color: #F0F2F5;
    border-radius: 10px;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu.ant-menu-inline-collapsed {
    background: #10585b;
    height: 94.5%;
}
.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open.ant-menu-submenu-active {
    background: #0d484a;
}
.ant-menu.ant-menu-sub.ant-menu-vertical {
    background-color: #0d484a;
}

@media (min-width: 500px) {
    .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.ant-menu {
        height: 98%;
    }
    .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-dark.ant-menu.ant-menu-inline-collapsed {
        height: 98%;
    }
}
